import { render, staticRenderFns } from "./pdf-viewer.html?vue&type=template&id=4fe1b895&scoped=true&external"
import script from "./pdf-viewer.js?vue&type=script&lang=js&external"
export * from "./pdf-viewer.js?vue&type=script&lang=js&external"
import style0 from "./pdf-viewer.scss?vue&type=style&index=0&id=4fe1b895&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe1b895",
  null
  
)

export default component.exports