<template>
  <div class="pdf-zoom">
    <a @click.prevent.stop="zoomOut" class="icon" :disabled="isDisabled"><i class="fa fa-minus" aria-hidden="true"></i></a>
    <a @click.prevent.stop="zoomIn" class="icon" :disabled="isDisabled"><i class="fa fa-plus" aria-hidden="true"></i></a>
    <a @click.prevent.stop="fitWidth" class="icon" :disabled="isDisabled"><i class="fa fa-expand" aria-hidden="true"></i></a>
    <a @click.prevent.stop="fitAuto" class="icon" :disabled="isDisabled"><i class="fa fa-compress" aria-hidden="true"></i></a>
  </div>
</template>

<script>

export default {
  name: 'PDFZoom',

  components: {
  },

  props: {
    scale: {
      type: Number,
    },
    increment: {
      type: Number,
      default: 0.25,
    },
  },

  computed: {
    isDisabled() {
      return !this.scale;
    },
  },

  methods: {
    zoomIn() {
      this.updateScale(this.scale + this.increment);
    },

    zoomOut() {
      if (this.scale <= this.increment) return;
      this.updateScale(this.scale - this.increment);
    },

    updateScale(scale) {
      this.$emit('change', {scale});
    },

    fitWidth() {
      this.$emit('fit', 'width');
    },

    fitAuto() {
      this.$emit('fit', 'auto');
    },
  },
}
</script>

<style>
.pdf-zoom .icon{
  margin-right:14px;
  cursor: pointer;
  display: inline-block;
  color: #ffffff;
  vertical-align:middle;font-size:15px;}
  .pdf-zoom .icon:last-child{margin-right:0px;}
</style>
