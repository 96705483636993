<template>
  <div class="pdf-paginator">
    <button v-on:click="minus(value)" class="mr-3"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
    <template v-if="pageCount">
      Page
       <span class="mr-3 ml-3">{{value}} </span> /
      <span  class="ml-3">{{ pageCount }}</span>
    </template>
    <input v-else type="number" />
    <button v-on:click="plus(value, pageCount)" class="ml-3"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
  </div>
</template>

<script>
export default {
  name: "PDFPaginator",

  props: {
    value: Number,
    pageCount: Number,
  },

  data() {
    return {
    }
  },

  methods: {
    input(event) {
      this.$emit("input", parseInt(event.target.value));
    },
    plus(val, pageCount) {
      if (val < pageCount) {
        val++;
        this.$emit("input", parseInt(val));
      }
    },
    minus(val) {
      if (val > 1) {
        val--;
        this.$emit("input", parseInt(val));
      }
    }
  },

  watch: {
    pageCount() {
      this.$emit("input", 1);
    }
  },
  created(){
  }
};
</script>
<style>
.pdf-paginator 
 button {
    background: none;
    border: none;
    color: #fff;
    outline:none;border:none;
}
.pdf-paginator {
  color: white;
  margin-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding: 13px 20px 13px 0;
  font-size: 14px;
}
.pdf-paginator input {
  background: none;
  color: #ffffff;
  border: none;
  outline: none;
  box-shadow: none;
  width: 30px;
  margin-left: 15px;
}
</style>
